import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Layout } from "../layout/Layout";
import Seo from "../components/common/Seo";

const Legal = ({ pageContext }) => {
  const { title, body, image, cards_title, cards } = pageContext.pageData;
  const heroBackgroundImage = getImage(image.imageFile);

  return (
    <Layout pageId="terms-legal" isHeaderTransparent={true} headerWhiteText={true}>
      <section className="relative h-[280px] overflow-hidden bg-center md:h-[360px]">
        <GatsbyImage image={heroBackgroundImage} className="absolute left-0 top-0 min-h-full w-full" />
        <div className="absolute left-0 top-1/2 z-10 w-full -translate-y-1/2">
          <h1 className="w-full text-center text-[32px] font-bold leading-10 text-white md:text-6xl">{title}</h1>
        </div>
      </section>

      <section className="pb-20 pt-14 md:pb-36 md:pt-20">
        <div className="container">
          <div className="row">
            <div className="mx-auto md:col-10">
              {cards?.length > 0 && (
                <div className="prose max-w-none prose-p:text-[#697276]">
                  {cards_title && <h2>{cards_title}</h2>}
                  <div className="mb-8 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {cards.map((card) => (
                      <div className="rounded-2xl border px-6" dangerouslySetInnerHTML={{ __html: card.body }} />
                    ))}
                  </div>
                </div>
              )}
              <div className="prose max-w-none prose-p:text-[#697276]" dangerouslySetInnerHTML={{ __html: body }} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const Head = ({ pageContext }) => <Seo title={`${pageContext.pageData.title} | Hisense`} />;

export default Legal;
